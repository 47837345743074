@import "./variables.scss";

.bg-blue {
    background: lightblue,
}

.bg-whitesmoke {
    background: whitesmoke;
}

.bg-darkgrey {
    background: darkgrey;
}

.bg-lightgrey {
    background: lightgrey;
}

.scroll-x {
    overflow-x: auto !important;
}

td.custom-kendo-cell {
    padding-top: 0;
    padding-bottom: 0;
    border: 1px solid #555 !important;
    font-size: 12px;
}

.on-hover:hover {
    filter: brightness(97%);
}

.on-hover-grey {
    &:hover {
        color: grey !important;
    }
}

.on-hover-opacity {
    &:hover {
        opacity: 0.5 !important;
    }
}

.lightgrey {
    color: lightgrey !important;
}

.border-none {
    border: none !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}  

.on-alt {
    filter: brightness(103%);
}

.in-underwriting.alt-grid-row {
    background-color: #9fa0e0 !important;
}

.clientChangeReason-ModalHeader {
    background-color: $itm-dark-blue;
    color: white !important;
}

.fasano-red {
    color: $fasano-red !important;
}

.fasano-button-red {
    background: $fasano-red !important;
    color: white !important;

    &:hover {
        opacity: 0.7;
    }
}

.font-family-Helvetica {
    font-family: Helvetica, Arial, Verdana, sans-serif !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.em-font-size-small {
    font-size: 0.8em !important;
}

.font-weight-bold {
    font-weight: bold !important;
}

.font-weight-normal {
    font-weight: normal !important;
}

.pale-blue-background {
    background-color: #c7d8f1f8 !important;
}

.p-inline-mr-30 {
    margin-right: 30px;
    display: inline;
    font-weight: normal !important;
}
.display-inline {
    display: inline !important;
}
.text-muted {
    color: #798088 !important;
}
.color-blue {
    color: $semantic-blue;
}
.itm-header {
    color: $semantic-blue;
}
.button-itm-blue {
    background-color: $semantic-blue;
    color: white !important;

    &:hover {
        background-color: $itm-dark-blue;
    }
}

.button-itm-darkblue > button {
    background-color: $itm-dark-blue;
    color: white !important;

    &:hover {
        background-color: $semantic-blue;
    }
}

.report-textArea {
    width: 100%;
    height: 6.9em;
    font-size: 0.95rem;
    padding-top: 7px;
    padding-left: 7px;
    border-radius: 5px;
    border-color: rgba(128, 128, 128, 0.474);

    &:focus {
        outline: none;
        box-shadow: 0 0 1.5px 1.5px rgba(5, 141, 246, 0.493);
    }
}

.report-generator-report-label {
    font-size: 1.3rem;
    padding-top: 15px;
    color: $semantic-blue;
    font-weight: bold;
    word-wrap: break-word;
    text-align: right;
    padding-right: 0;
    margin-right: -40px;
}

.report-toolbar-firstColumn {
    padding-top: 0.3em;
    font-size: 1.3rem;
}

.xButton-toolbar {
    margin-left: 0 !important;
    height: 37.3px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.inputBox-toolbar > input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.inputBox-toolbar {
    float: right !important;
}

.link-as-button {
    @extend .button-itm-blue;
    padding: 0.78571429em 1.5em 0.78571429em;
    border-radius: 0.28571429rem;
    font-size: 1em;
    font-weight: bold;
}

.font-warning {
    color: #c41e3a;
    font-weight: bold;
}

.ui.dropdown .menu .active.item {
    background-color: $semantic-blue;
    color: #fff;

    &:hover {
        opacity: 0.7;
    }
}

.invoice_report.ui.dropdown .menu .item,
.invoice_report.ui.dropdown .menu .active.item {
    font-size: 16px !important;
}

.form-disabled input,
.form-disabled textarea,
.form-disabled .search,
.form-disabled .selection,
.form-disabled .dropdown {
    background-color: #f4f4f4 !important;
    pointer-events: none;
}

.mt-negative-4px input {
    margin-top: -4px !important;
    height: 24px;
}

.mt-negative-4px {
    margin-top: -4px !important;
}

.mt-4px {
    margin-top: 4px !important;
}

.non-editable-editor .k-editor-content {
    pointer-events: none;
}

.editable-editor {
    overflow-x: hidden;
    padding: 10px;
}

.editable-editor .ProseMirror-focused {
    outline: none;
}

.message-template-editing {
    background-color: rgba(114, 118, 118, 0.05);
    outline: 2px solid $semantic-blue;
}

.message-template-editing .k-editor-content {
    padding-top: 10px;
}

.button-facet-filter-color > .button {
    background-color: $semantic-blue;
    color: white !important;
    font-size: 1.2rem;

    &:hover {
        opacity: 0.5 !important;
    }
}

.button-itm-blue > .button {
    background-color: $semantic-blue;
    color: white !important;

    &:hover {
        background-color: $itm-dark-blue;
    }
}

.facetFilter > .ff-group .ff-group-heading {
    background-color: $semantic-blue;
    border: 1px solid $semantic-blue;
    font-size: 1.15rem;
}

.facetFilter .ff-group .ff-group-item .ff-facet-label {
    font-size: 1.05rem;
}

.mergeClient-input {
    font-size: 16px !important;
    margin-bottom: 5px !important;
    height: 32px;
}

.mergeClient-input > .label {
    background-color: rgba(147, 139, 139, 0.05) !important;
    width: 9em;
    padding-top: 10px !important;
    border-right: 1px solid rgba(34, 36, 38, 0.15);
}

.link-button.ui.button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: blue;
    cursor: pointer;

    &:focus {
        color: red;
    }
}

.compare-link-button.ui.button {
    background: none;
    border: none;
    padding: 0;
    font-size: 15px;
    color: $semantic-blue;
    cursor: pointer;

    &:hover {
        color: $itm-dark-blue;
    }
}

.mergeClient-warning {
    color: red;
    text-decoration: underline;
    font-size: 15px;
}

.mergeClient-column-header {
    font-size: 1.7em !important;
    white-space: nowrap !important;
}

.mergeClient-column-header-row {
    height: 2.5rem;
}

.ModalHeader-color-semantic-blue {
    background-color: $semantic-blue;
    color: white !important;
}

.page-img {
    // border: 1px solid lightgray;
    box-shadow: 0 0 20px lightgray;
}

.reportSummary-grid .k-i-caret-alt-down::before {
    content: none !important;
}

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25
        ),
        2: (
            $spacer * 0.5
        ),
        3: $spacer,
        4: (
            $spacer * 1.5
        ),
        5: (
            $spacer * 3
        )
    ),
    $spacers
);
@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $length in $spacers {
        .#{$abbrev}-#{$size} {
            #{$prop}: $length !important;
        }
        .#{$abbrev}t-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-top: $length !important;
        }
        .#{$abbrev}r-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-right: $length !important;
        }
        .#{$abbrev}b-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-left: $length !important;
        }
    }
}

.no-border-table{
    border: none !important;
}

.no-border-table td{
    border: none !important;
}

.conditions-table td {
    border-top: 1px solid white !important;
    border-bottom: 1px solid white !important;
}

.box-shadow-blue {
    box-shadow: 0 0 2px 2px lightblue;
}

.clearable-icon-size > .dropdown.icon {
    line-height: 0.2 !important;
    font-size: 12px !important;
    padding-right: 5px !important;
}

.uw-compare-checkbox.ui.checkbox label:after {
    background-color: $semantic-blue;
    color: white !important;
    border-radius: 2px;
}

.pointer-events-none {
    pointer-events: none;
}

.hidden {
    visibility: hidden !important;
}

.cursor-pointer {
    cursor: pointer;
}
