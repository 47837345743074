@import "@progress/kendo-theme-default/dist/all.scss";

.k-grid .ui.grid {
    // semantic grids won't take 100% width without when inside kendo components
    flex-grow: 1;
}

.k-grid {
    font-family: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

.k-grid a {
    color: #168abf;
}
.k-grid-dark a {
    color: #044687;
}
.k-grid .k-alt,
.k-grid-header .k-header > .k-link {
    color: #000;
}

.k-custom-grid .k-filter-row td,
.k-filter-row th {
    border-width: 0 0 1px 1px;
    white-space: nowrap;
    padding: 0.3rem 0.3rem;
}

.k-custom-grid tbody tr td {
    text-align: left;
    vertical-align: top;
}

.k-custom-grid .k-grid th,
.k-grid td {
    padding: 0.45rem 0.35rem;
}

.k-pager-sizes .k-dropdownlist {
    width: 5.75rem !important;
}

.k-animation-container {
    z-index: 4000 !important;
}
